<template>
  <div class="flex flex-col">
    <div
      class="flex flex-col items-start self-center w-11/12 mt-8 bg-yellow p-4"
    >
      <h2 class="text-2xl font-bold mt-8">Premios</h2>
      <div class="flex flex-col items-start my-4">
        <p class="mb-0">
          <span class="font-bold">Premios redimidos:</span>
          {{ total }}
        </p>
      </div>
      <div class="grid grid-cols-4 gap-x-4 gap-y-2 w-full">
        <div
          class="p-4 flex flex-col items-start justify-between bg-white rounded-lg"
          v-for="award in awards"
          :key="award.id"
        >
          <h3 class="font-bold mb-2">{{ award.name }}</h3>
          <div class="flex flex-col items-start">
            <p class="mb-0">
              <span class="font-bold">Total:</span>
              {{ award.total }}
            </p>
            <p class="mb-0">
              <span class="font-bold">Usados:</span>
              {{ award.redeemed }}
            </p>
            <p class="mb-0">
              <span class="font-bold">Restantes:</span> {{ award.available }}
            </p>
            <p class="mb-0">
              <span class="font-bold">Visible en la App:</span>
              {{ award.visible ? "Si" : "No" }}
            </p>
            <button
              class="my-3 self-center px-5 py-3 rounded-lg border border-black bg-gray text-white"
              @click="
                () => {
                  awardSelected = award;
                  available = award.available;
                  visible = award.visible;
                }
              "
            >
              EDITAR PREMIO
            </button>
          </div>
        </div>
      </div>

      <!-- INICIO MODAL EDITAR PREMIO -->
      <div
        v-if="awardSelected"
        class="z-20 fixed top-0 left-0 w-full min-h-screen flex items-center justify-center bg-black bg-opacity-60"
      >
        <div class="px-20 py-8 rounded-3xl bg-white flex flex-col items-center">
          <h2 class="mb-5 text-3xl font-bold">{{ awardSelected.name }}</h2>
          <p class="text-2xl">
            <span class="font-bold">Total:</span>
            {{ awardSelected.total }}
          </p>
          <p class="text-2xl">
            <span class="font-bold">Usados:</span>
            {{ awardSelected.redeemed }}
          </p>
          <div class="mb-4 flex items-center gap-3">
            <label class="text-2xl font-bold">Restantes:</label>
            <input
              type="text"
              v-model="available"
              class="text-2xl px-4 py-2 border-solid border border-black rounded-md"
            />
          </div>
          <div class="mb-6 flex items-center gap-3">
            <label class="text-2xl font-bold">Visible en la App:</label>
            <div
              class="p-3 rounded-md border border-black text-2xl"
              :class="visible ? 'bg-yellow text-white' : ''"
              @click="() => (visible = true)"
            >
              Si
            </div>
            <div
              class="p-3 rounded-md border border-black text-2xl"
              :class="visible ? '' : 'bg-yellow text-white'"
              @click="() => (visible = false)"
            >
              No
            </div>
          </div>
          <v-alert v-if="errorMessage" type="error">
            {{ errorMessage }}
          </v-alert>
          <div class="flex items-center gap-7">
            <button
              class="my-3 self-center px-5 py-3 rounded-lg border border-black bg-yellow text-white"
              @click="editAward"
            >
              GUARDAR
            </button>
            <button
              class="my-3 self-center px-5 py-3 rounded-lg border border-black bg-gray text-white"
              @click="
                () => {
                  awardSelected = null;
                  available = 0;
                  visible = false;
                }
              "
            >
              CANCELAR
            </button>
          </div>
        </div>
      </div>
      <!-- FINAL MODAL EDITAR PREMIO -->
    </div>
    <Loader :isLoading="isLoading" />
  </div>
</template>

<script>
import { db, auth } from "../../firebase.js";
import Loader from "../../components/Loader.vue";
export default {
  components: { Loader },
  data() {
    return {
      isLoading: true,
      errorMessage: null,
      awards: [],
      awardSelected: null,
      available: 0,
      visible: false,
    };
  },
  async mounted() {
    const stats = (await db.collection("--stats--").doc("stats").get()).data();
    const awards = (
      await db.collection("awards").where("year", "==", "2024").get()
    ).docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    this.awards = awards.map((award) => {
      const redeemed = stats[`award-${award.id}`] ?? 0;
      return {
        id: award.id,
        name: award.name,
        visible: award.visible,
        total: award.qty + redeemed,
        redeemed,
        available: award.qty,
      };
    });

    this.isLoading = false;
  },
  computed: {
    total: {
      get() {
        return this.awards.reduce((acc, award) => acc + award.redeemed, 0);
      },
    },
  },
  methods: {
    async editAward() {
      this.errorMessage = null;
      if (!this.available)
        return (this.errorMessage = "Restantes debe tener un valor");
      if (
        parseInt(this.available) === this.awardSelected.available &&
        this.visible === this.awardSelected.visible
      )
        return;

      this.isLoading = true;
      try {
        const awardRef = db.collection("awards").doc(this.awardSelected.id);
        const user = auth.currentUser;

        await db.runTransaction(async (transaction) => {
          return transaction.get(awardRef).then((doc) => {
            if (!doc.exists) {
              throw "Documento del premio no existe";
            }
            const currentData = this.awards.find(
              (award) => award.id === this.awardSelected.id
            );

            if (currentData.available === doc.data().qty) {
              transaction.update(awardRef, {
                qty: parseInt(this.available),
                visible: this.visible,
              });
              transaction.set(db.collection("logs").doc(), {
                eventName: "Actualización de premios",
                companyId: "900123123-001",
                timestamp: Date.now(),
                uid: user.uid,
                newQty: parseInt(this.available),
                prevQty: parseInt(currentData.available),
                newAvailable: this.visible,
                prevAvailable: currentData.visible,
                awardId: currentData.id,
              });
            } else {
              return Promise.reject(
                "Han ocurrido cambios en las redenciones, por favor recarga la página"
              );
            }
          });
        });
        const awardIdx = this.awards.findIndex(
          (award) => award.id === this.awardSelected.id
        );
        this.awards.splice(awardIdx, 1, {
          ...this.awards[awardIdx],
          total:
            parseInt(this.awardSelected.redeemed) + parseInt(this.available),
          available: parseInt(this.available),
          visible: this.visible,
        });
        this.awardSelected = null;
        this.available = 0;
        this.visible = false;
      } catch (error) {
        this.errorMessage = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border-style: solid;
}
.award-name {
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 1; /* number of lines to show */
  // -webkit-box-orient: vertical;
  font-weight: 700;
}
.bg-gray {
  background-color: rgba(107, 114, 128);
}
</style>
